<template>
    <div class="login-bos flex-column-between">
        <div class="login-box">
            <img class="login-md5" src="@/assets/image/passport/img1.png" alt="">
            <div class="login-logo flex-row-start">
                <img :src="installSite.general_backend_url?installSite.general_backend_url:'@/assets/image/passport/img2.png'"  alt="">
                <!-- <div>{{installSite.site_name?installSite.site_name:'-'}}</div> -->
            </div>
            <div class="login-all flex-row-center">
                <div class="login-carousel">
                    <a-carousel autoplay :after-change="onChange" class="login-carousel">
                        <div v-for="(item, index) in carousel" :key="index">
                            <img :src="item" alt="" class="carousel-img">
                        </div>
                    </a-carousel>
                </div>
                <div class="login-form-box">
                    <div class="text-center ft20 ftw600 cl-main">{{installSite.site_name?installSite.site_name:'-'}}</div>
                    <div class="login-input-item mt32 flex alcenter space">
                        <a-input v-model="form.username" prefix="登录帐号" :maxLength="11" class="username"></a-input>
                    </div>
                    <div class="login-input-item mt24 flex alcenter space">
                        <a-input type="password" v-model="form.password" prefix="登录密码" class="pwd"></a-input>
                    </div>
                    <div class="mt32 login-btn">
                        <a-button style="width: 100%;" @click="loginAct" type="primary">立即登录</a-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="login-bottom flex-row-center">站点域名：{{installSite.domain_name?installSite.domain_name:'-'}}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            BASE_IMG_URL:window.global_config.BASE_IMG_URL,
            installSite: {},
            carousel: [
                require('@/assets/image/passport/nav1.svg'),
                require('@/assets/image/passport/nav2.svg'),
                require('@/assets/image/passport/nav3.svg'),
                require('@/assets/image/passport/nav4.svg')
            ],
            loading: false,
            code: '',
            form: {
                username: '',
                password: '',
            },
            siteInfo: null
        }
    },
    created() {
        // this.getCode();
        // this.getSiteInfo();
    },

    mounted() {
        //绑定事件
        window.addEventListener('keydown', this.keyDown);
        this.$http.api('api/Common/InstallSite').then(res => {
            res.data.general_backend_url = this.BASE_IMG_URL + res.data.general_backend_url
            this.installSite = res.data
        }).catch(res => {
            console.log(res);
        });
    },
    methods: {
        onChange(res) {
            // console.log(res, '!!!')
        },
        getSiteInfo() {
            this.$http.api('platform/siteInfo').then(res => {
                this.siteInfo = res.site;
            }).catch(res => {
                console.log(res);
            });
        },
        getCode() {
            this.$http.api('platform/admin/getCode', {

            }).then(res => {
                this.code = res.code;
            }).catch(res => {
                console.log(res);
            });

        },
        loginAct() {
            // this.$router.push('/home');
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('api/user/login', {
                // token: this.code,
                account: this.form.username,
                password: this.form.password,
            }).then(res => {
                localStorage.setItem('admin-token', res.data.userinfo.token);
                this.$message.success('登录成功', 1, () => {
                    this.$router.push('/home');
                })
            }).catch(res => {
                console.log(res);
            }).finally(() => {
                this.loading = false;
            })

        },
        keyDown(e) {
            //如果是回车则执行登录方法
            if (e.keyCode == 13) {
                this.loginAct();
            }
        },
    },
    destroyed() {
        window.removeEventListener('keydown', this.keyDown, false);
    }
}
</script>

<style lang="less" scoped>
.login-bos {
    width: 100%;
    height: 100%;
    min-width: 1200px;
    min-height: 800px;
    overflow: hidden;

    .login-box {
        flex: 1;
        width: 100%;
        background-color: rgba(0, 121, 254, 1);
        background-image: url("../../src/assets/image/md5__f3.png");
        background-repeat: no-repeat;
        background-size: 5000px 90px;
        background-position: 0px bottom;
        position: relative;
        display: flex;
        flex-direction: column;

        .login-md5 {
            width: 4660px;
            height: 462px;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .login-logo {
            height: 120px;
            width: 100%;
            color: #ffffff;
            font-size: 54px;
            padding-left: 50px;

            img {
                width: 115px;
                height: 60px;
                margin-right: 20px;
            }
        }

        .login-all {
            flex: 1;
            padding-bottom: 150px;

            .login-carousel {
                width: 540px;
                height: 300px;

                .carousel-img {
                    width: 540px;
                    height: 300px;
                }
            }

            .login-form-box {
                width: 420px;
                height: 362px;
                background: #ffffff;
                box-shadow: 0px 4px 32px -8px rgba(0, 0, 0, 0.02);
                border-radius: 24px;
                margin-top: 36px;
                margin-left: 25%;
                padding: 48px 40px;

                .login-input-item {
                    width: 100%;
                    height: 50px;
                    border-radius: 30px;
                    border: 1px solid #dcdfe6;
                    padding: 0 24px;
                }

                .login-input-item /deep/.ant-input {
                    border: none;
                    font-size: 16px;
                }

                .login-input-item
                    .ant-input-affix-wrapper
                    /deep/.ant-input:not(:first-child) {
                    padding-left: 88px;
                }

                .login-input-item .username {
                    width: 100%;
                }

                .login-input-item .pwd {
                    width: 200px;
                }

                .login-input-item
                    .ant-input-affix-wrapper
                    /deep/.ant-input-prefix {
                    color: #a6aec2;
                    font-size: 16px;
                }

                .login-input-item /deep/.ant-input:focus {
                    border: none;
                    box-shadow: none;
                }

                .login-btn /deep/.ant-btn {
                    height: 50px;
                    border-radius: 25px;
                    font-size: 16px;
                    background-color: #1890ff;
                }
            }

            // .login-is{
            // 	padding: 30px 35px;
            // 	background-color: #ffffff;
            // 	box-shadow: 0px 0px 30px 0px rgba(113, 113, 113, 0.1);
            // }
        }
    }

    .login-bottom {
        height: 100px;
        width: 100%;
        background-color: #ffffff;
        font-size: 14px;
    }

    /deep/ .slick-dots-bottom {
        bottom: -20px !important;
    }
}
</style>
